import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Switch,
} from 'antd';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { AnniversarySettings } from '../anniversary/components/AnniversarySettings';

import { FeatureFlagQuery } from '@/react/services/FeatureToggleService';
import { CdCard } from '@/react/shared/components/CdCard';
import { gettextCatalog } from '@/react/services/I18nService';
import UploadFile from '@/react/people/components/UploadFile';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { CdCheckIcon, CdClose, CdSave } from '@/react/shared/components/Icons';
import cdApp from '@/react/config';
import { PeopleSignUpDemo } from '@/react/people/components/PeopleSignUpDemo';
import { CdrColorPicker } from '@/react/shared/components/cd-color-picker/CdrColorPicker';
import { CdSalesButton } from '@/react/shared/components/CdSalesButton';
import { buyingSmsPackages } from '@/react/homepage/utils/support-articles';
import AuthorizationService from '@/react/services/AuthorizationService';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { required } from '@/react/shared/antd/validations/common';

const { TextArea } = Input;

const SettingsContainer = styled(CdCard)`
  &&&& {
    margin: 12px 0;
    :first-child {
      margin: 0 0 12px 0;
    }
  }
`;

const SmsBalanceContainer = styled(CdCard)`
  &&&& {
    width: 100%;
    .ant-card-body {
      padding: 16px;
      text-align: center;
    }
  }
`;

const SmsBalanceCount = styled(Typography.Title)`
  &&&& {
    font-size: 36px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
`;

const LogoPreview = styled.img`
  padding: 0 16px 16px 16px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const ImageHelperText = styled.p`
  margin: 25px 0;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

const EmbededHeader = styled.header`
  margin-top: 4px;
  font-weight: 700;
`;
const EmbededContainer = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 0 16px;
`;
const NewsletterSignupWidgetDiv = styled.div`
  display: flex;
  gap: 0 16px;
`;

function DisplaySmsBalance() {
  const {
    organizationSettings: { smsBalance },
  } = useOrganization();
  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <SmsBalanceContainer $fill={false}>
        <SmsBalanceCount
          level={2}
          type={smsBalance >= 100 ? 'success' : 'danger'}
        >
          {smsBalance}
        </SmsBalanceCount>
        <Typography.Text>
          {gettextCatalog.getString('SMS messages left')}
        </Typography.Text>
      </SmsBalanceContainer>
      <CdSalesButton
        block
        href={buyingSmsPackages()}
        target="_blank"
        rel="noreferrer"
      >
        {gettextCatalog.getString('Buy SMS packages')}
      </CdSalesButton>
    </Space>
  );
}
const getCodeSignUpWidget = () => {
  const organizationId = cdApp.organization.id;
  NiceModal.show('GetCodeSignUpWidget', { organizationId });
};

function PeopleSettingsForm({ form }) {
  const {
    organizationSettings,
    updateSettings,
    uploadPeopleLogo,
    removePeopleLogo,
  } = useOrganization();
  const showAnniversaryApp =
    useRecoilValue(FeatureFlagQuery('anniversary_app')) &&
    AuthorizationService.hasPackage('anniversary');
  const removeLogoNoBubble = useCallback(
    (e): React.MouseEventHandler<HTMLElement> => {
      e.stopPropagation();
      removePeopleLogo();
      return;
    },
    [removePeopleLogo]
  );
  const hasPeoplePackage = AuthorizationService.hasPackage('people');
  const onFinish = updateSettings;
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={organizationSettings}
    >
      <SettingsContainer title={gettextCatalog.getString('SMS')}>
        <Form.Item
          name="smsSenderName"
          required
          rules={[
            {
              max: 11,
              min: 2,
              message: gettextCatalog.getString(
                'Name must be more then 2 and less then 11!'
              ),
              type: 'string',
            },
            {
              pattern: new RegExp('^[a-zA-Z0-9]*$', 'i'),
              message: gettextCatalog.getString(
                'Invalid characters in the sms sender name!'
              ),
              type: 'string',
            },
          ]}
          label={gettextCatalog.getString('Sender name')}
          extra={gettextCatalog.getString(`The SMS sender name must contain only letters and digits. No spaces or special
              characters (e.g: %,€,#,Æ,Ø,Å,Ä,Ö,Ü) are allowed.`)}
        >
          <Input
            placeholder={gettextCatalog.getString(
              'The name used when sending out text messages.'
            )}
          />
        </Form.Item>
      </SettingsContainer>

      <SettingsContainer
        title={gettextCatalog.getString('Organization information')}
      >
        <Form.Item
          name="organizationEmail"
          required
          rules={[
            required(
              gettextCatalog.getString('You are required to supply a email')
            ),
            {
              type: 'email',
              message: gettextCatalog.getString('Invalid email'),
            },
          ]}
          label={gettextCatalog.getString('Main e-mail address')}
          extra={gettextCatalog.getString(`The main e-mail address of your church, used to send out messages. Replies will
                afterwards be sent to this address.`)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="siteAddress"
          label={gettextCatalog.getString('Address')}
          help={gettextCatalog.getString(
            `The address is shown at the bottom of the newsletter.`
          )}
        >
          <TextArea rows={4} />
        </Form.Item>
      </SettingsContainer>

      <SettingsContainer title={gettextCatalog.getString('Contacts table')}>
        <Form.Item
          name="displayOrder"
          label={gettextCatalog.getString('Display order')}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="firstLast">
                {gettextCatalog.getString('First name, Last name')}
              </Radio>
              <Radio value="lastFirst">
                {gettextCatalog.getString('Last name, First name')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </SettingsContainer>

      <SettingsContainer
        title={gettextCatalog.getString(
          'Newsletter sign-up widget for your website'
        )}
      >
        <NewsletterSignupWidgetDiv>
          <div>
            <div style={{ display: 'flex' }}>
              <CdTooltip
                title={
                  !hasPeoplePackage &&
                  gettextCatalog.getString(
                    'Newsletter widget is not included in your subscription'
                  )
                }
              >
                <Form.Item
                  name="newsletterWidgetEnabled"
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={<CdCheckIcon />}
                    unCheckedChildren={<CdClose />}
                    style={{ marginRight: '12px' }}
                    disabled={!hasPeoplePackage}
                  />
                </Form.Item>
              </CdTooltip>
              <p style={{ marginTop: '4px' }}>
                {gettextCatalog.getString(
                  'Add the newsletter sign-up widget to your website'
                )}
              </p>
            </div>
            <p style={{ color: 'gray', marginTop: '-16px' }}>
              {gettextCatalog.getString(
                `If disabled the newsletter widget will not load on your website even when installed`
              )}
            </p>
            <EmbededContainer>
              <EmbededHeader>
                {gettextCatalog.getString(
                  'Embed javascript code that you can add to any website page header'
                )}
              </EmbededHeader>
              <Button
                type="default"
                onClick={getCodeSignUpWidget}
                disabled={!hasPeoplePackage}
              >
                {gettextCatalog.getString('Get code')}
              </Button>
            </EmbededContainer>
            <p>
              {gettextCatalog.getString(
                'If you do not use ChurchDesk website you can add the newsletter widget to your any to let people sign-up up for your newsletters. The widget will load shortly after the webpage has loaded.'
              )}
            </p>
          </div>
          <PeopleSignUpDemo />
        </NewsletterSignupWidgetDiv>
      </SettingsContainer>

      <SettingsContainer
        title={gettextCatalog.getString('Organization and newsletter logo')}
      >
        <Form.Item
          name="uploadLogo"
          help={gettextCatalog.getString(`This will appear in the header section of the newsletter. Your image should not exceed
          a width or height of 600 pixels or 120 pixels respectively.`)}
        >
          <UploadFile
            uploadFile={uploadPeopleLogo}
            maxFileSize={10}
            allowedFileExtensions={['PNG', 'JPEG', 'JPG']}
            deleteFile={removePeopleLogo}
          >
            <LogoPreview
              src={organizationSettings.logo.medium || '/img/placeholder.jpeg'}
              alt={`${organizationSettings.name} logo`}
            />
            <ImageHelperText>
              {gettextCatalog.getString(
                'Drop an image or click anywhere to browse'
              )}
            </ImageHelperText>
            <p>{gettextCatalog.getString('JPEG or PNG')}</p>
            {organizationSettings.logo.medium && (
              <Button onClick={removeLogoNoBubble}>
                {gettextCatalog.getString('Remove')}
              </Button>
            )}
          </UploadFile>
        </Form.Item>
      </SettingsContainer>

      <SettingsContainer title={gettextCatalog.getString('Newsletter colors')}>
        <Form.Item
          name="newsletterColor"
          help={gettextCatalog.getString(
            `Choose an accent color for your standard newsletter.`
          )}
        >
          <CdrColorPicker />
        </Form.Item>
      </SettingsContainer>
      {showAnniversaryApp && (
        <SettingsContainer title={gettextCatalog.getString('Anniversary app')}>
          <AnniversarySettings />
        </SettingsContainer>
      )}
    </Form>
  );
}

export default function PeopleSettings() {
  const [form] = Form.useForm();
  const onFinish = useCallback(() => form.submit(), [form]);

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('People settings'),
        subTitle: gettextCatalog.getString(
          'Check your SMS balance or customize how the People module should work for you'
        ),
        extra: (
          <Button
            icon={<CdSave />}
            type="primary"
            size="large"
            onClick={onFinish}
          >
            {gettextCatalog.getString('Save')}
          </Button>
        ),
      }}
    >
      <Row wrap={false}>
        <Col span={16}>
          <PeopleSettingsForm form={form} />
        </Col>
        <Col span={7} push={1}>
          <DisplaySmsBalance />
        </Col>
      </Row>
    </CdPage>
  );
}
